var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "main-content" }, [
      _c(
        "div",
        { staticClass: "nav_top" },
        [
          _vm.urlName == "start"
            ? _c(
                "div",
                { staticClass: "top-button" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.urgentFlag = !_vm.urgentFlag
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("lang_Urgent")) + " "),
                      _c("el-checkbox", { attrs: { value: _vm.urgentFlag } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tabs",
            {
              staticClass: "custom-tabs",
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: _vm.$t("lang_client_toggle_form"),
                  name: "form",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: _vm.$t("lang_client_toggle_flowchart"),
                  name: "flow",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currTabName == "form",
              expression: "currTabName == 'form'",
            },
          ],
          staticClass: "main-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "deal-content",
              style: { color: _vm.fontColor ? _vm.fontColor : "black" },
            },
            [
              _c("form-viewer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currTabName == "form",
                    expression: "currTabName == 'form'",
                  },
                ],
                ref: "formViewerRef",
                staticClass: "deal-form",
                style: { borderColor: _vm.borderColor },
                attrs: {
                  btnObj: _vm.currentLayoutBtn,
                  operation: _vm.operation,
                  isStart: true,
                },
                on: { submit: _vm.handleOperation },
              }),
              _c(
                "el-footer",
                { staticClass: "deal-btns" },
                _vm._l(_vm.curOptBtns, function (item) {
                  return _c(
                    "el-button",
                    {
                      key: item.actionKey,
                      staticClass: "deal-btn-item",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOperation(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.displayActionName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currTabName == "flow",
              expression: "currTabName == 'flow'",
            },
          ],
          staticClass: "main-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "deal-content",
              style: { color: _vm.fontColor ? _vm.fontColor : "black" },
            },
            [
              _c("flow-graph", {
                ref: "flowGrapgRef",
                staticClass: "deal-flow",
                attrs: { flowId: _vm.flowId, readonly: true },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }